const symbols = {
  NGN: "₦",
  USD: "$",
  GHS: "₵",
  EUR: "€",
  GBP: "£",
  ZAR: "R",
  KES: "K",
};

export default symbols;
